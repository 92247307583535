import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';
import lattin1 from './lattin1.gif';
import lattin2 from './lattin2.jpg';
import lattin3 from './lattin3.jpg';
import lattin4 from './lattin4.jpg';
import lattin5 from './lattin5.jpg';

const article = `
# Ecstasy in Eighteenth-Century Kildare?
## The strange fate of john lattin of morristown lattin (1731)
### Seán Donnelly

![lattin-1](lattin1.gif "Lattin 1")

This article was originally published in the Journal of the County Kildare Archaeological Society xviii, 4 (1998-9), 565-88, and is republished here by permission of the honorary editor of the journal, Dr Raymond Gillespie, Dept. of Modern History, National University of Ireland, Maynooth, co. Kildare. Some minor errors and slips have been corrected here, and several abbreviations in the references have been expanded.

![lattin-2](lattin2.jpg "Lattin 2")

In St David's Churchyard, Naas, co. Kildare, a handsome, well-preserved tombstone marks the ancestral grave of the Lattins of Morristown Lattin. Granted lands in the area during the reign of King John, 1199-1216, the family had also established themselves as merchants in Naas itself by the late sixteenth century. In 1641 the estate comprised a fairly modest 660 acres around Naas, a house and tenements in the town itself, as well as tenements and three castles elsewhere in the area. The principal holding was Morristown Moynagh (400 acres), later to be renamed Morristown Lattin; the name survives in the present townlands of Morristown and Morristown Lower, and Lattensbog nearby is also obviously named after the family. [1]

The Lattins succeeded in both remaining Roman Catholics and retaining their lands throughout the upheavals of the sixteenth and seventeenth centuries - they were one of an appreciable number of Pale families to do so [2] - and also avoided the gavelling clauses of the Penal Laws in the next century. [3] In fact they appear to have faced into the eighteenth century with a good deal of confidence, building the house at Morristown Lattin in 1692, which was to be added to in the early Georgian period and extensively remodelled in 1845. [4] Also in the last decade of the seventeenth century, the family acquired a Dublin residence (and business premises?) in the parish of St Michan's, Latin Court [sic], [4] now part of Greek Street. [5] They retained this house down to 1737, by which time they also had other house-property in nearby Capel Street. [6] Like some other minor Old English families of the Pale, the Lattins also seem to have avoided intermarriage with native Irish families. [7] The Irish forename 'Begnet', borne by two generations of Lattin daughters, came into the family by default in the 1660s, when William Lattin of Morristown married a Begnet Ball (d. 1678) as his first wife. [8]

An inscription the tombstone in St David's records that Patrick Lattin (1669-1732) of Morristown and his wife, Jane Alcock (1674-1764), commissioned it to replace one that William Lattin of Morristown Lattin (d. 1616) and his wife Anne Luttrell of Luttrellstown, co. Dublin, placed over the grave in 1600 and which had become broken. A Latin inscription from the earlier stone was copied on to the newer one, and the family coat-of-arms also appears on it. The burials recorded on this stone include that of Patrick Lattin himself, died 19 June 1732, his son, George, died 8 July 1773, aged fifty-nine, and George's wife, Catherine O'Ferrall of Balyna, co. Kildare, died 12 November 1800, aged sixty-six.[9] This couple were the last Lattins by blood or marriage to be interred in the family-grave. Their younger son, Ambrose, a soldier in the Austrian service, died in Germany in 1788, or 1789, while their elder, the charismatic Patrick Lattin (1762-1836), died in Paris, where he had lived for most of his life.[10] Morristown passed to his daughter, Pauline, who had married Alexander Mansfield of Yeomanstown, co. Kildare (1786-1842), and their descendants were to own Morristown until the 1960s. [11] The right of burial in St David's seems to have been extinguished, or else allowed to lapse, with the last of the male Lattins: the Mansfields were buried in Carragh Cemetery, Naas. [12]

Besides George, who succeeded to Morristown, five daughters were born to Patrick Lattin and Jane Alcock, [14] but the first burial recorded on the tombstone they commissioned in St David's was that of their elder son:

> Here lyeth the body of JOHN LATTON Eldest
> Son of the above PATRICK LATTON who Departed
> this life the Seventh day of July 1731 in the 21st
> year of his Age

This John Lattin is listed the family genealogy, but there are no further references to him in the surviving family papers. [15] Death at such a relatively early age in the eighteenth century was not unusual, and would normally be assumed to have been due to accident or illness. But the demise of Jack Lattin, as he was called, was far from usual, and the memory of his going remained alive in local and family tradition for nearly two centuries.

## I

In the late nineteenth century, a great-grandaughter of the younger Patrick Lattin's, Miss Maude Mansfield of Earlsfort Terrace, Dublin, [16] told the music collector P.W. Joyce (1827-1914) that Jack Lattin, her great-grandfather's uncle and a famous dancer, had died, aged twenty-one, in the middle of the eighteenth century. A family rhyme had it that,

> Jack Lattin dressed in satin
> Broke his heart of dancing
> He danced from Castle Browne
> To Morristown. [17]

Castle Browne, roughly eight miles from Morristown Lattin, was renamed by the Browne (later Wogan-Browne) family, who acquired it in 1667. The castle reverted to its old name, Clongowes Wood, when the Society of Jesus (the Jesuits) opened their boarding school there in 1814, having purchased the estate the previous year from General Michael Wogan Browne. [18]

A more widely-known version of the story had it that Jack Lattin danced between Morristown and Dublin. When George Mansfield (1845-1929) of Morristown Lattin, also a great-grandson of Patrick Lattin's, died in January 1929, an obituarist remarked that he had been related to Jack Lattin, who had danced from Morristown to Dublin, 'with heavy wagering on both sides'. [19] This version of the story had been published as early as 1807, when Lattin was then described as 'a Gentleman in the County of Kildare ... [who] one morning, ... danced from his own house to Dublin, but died a few days after from the fatigue'.[20] A century later, in 1910, P.W. Joyce also published the Dublin - Morristown version of the story, overlooking the account he had previously received from Maude Mansfield, and also post-dating the incident by a century:

> John Lattin of Morristown House county Kildare (near Naas) wagered he'd dance home to Morristown from Dublin - more than twenty miles - changing his dancing-steps every furlong: and won the wager.

Morristown, Jack Lattin's residence, is near Lyons, the seat of Lord Cloncurry, where Jack was often a guest in the first half of the last century. Lady Morgan has an entry in her Memoirs (1830): - 'Returned from Lyons - Lord Cloncurry's, a large party - the first day good - Sheil, Curran, Jack Lattin.' [21]

Lady Morgan (?1776-1859) made the error here, not Joyce, who had, ironically, previously corrected it in his note of Maud Mansfield's account. At the end of June and beginning of July 1827, Lady Morgan spent several days at Lyons, co. Kildare, the residence of Valentine Lawless, Lord Cloncurry. There she enjoyed the brilliant wit and charming conversation of 'Jack Lattan', then on his annual visit home from Paris, who also took her to visit Morristown Lattin, 'in his old-fashioned French coach'. [22] Obviously this was Patrick Lattin, who was a close friend of Cloncurry's and one of the great wits and raconteurs of his day. [23] Lady Morgan's slip - she twice wrote 'Jack Lattan' in her journal - is difficult to understand, as she mentions Patrick Lattin elsewhere under his proper forename, or at least initial. [24] Perhaps Patrick Lattin had told her the story of his uncle's death, just under a century previously, and Jack's name stuck in her mind.

Apart from his epitaph and the traditions concerning the manner of his death, Jack Lattin is to be glimpsed in a work by the notoriously eccentric author, Thomas Amory (c. 1691-1788). Born in England, Amory was the son of Thomas Amory of Dublin, and later of Bunratty Castle, co. Clare, William III's secretary for forfeited estates in Ireland. The younger Amory spent much of his early life in Dublin, before settling in London as a man of letters. He is now best-remembered for The life of John Buncle Esq: (vol. I, London, 1756; vol. II, London, 1766), a work politely dismissed in the late nineteenth century as the product of 'one disordered in his intellect'. [25] Modern criticism has been kinder to Amory, and John Buncle is recognised as being an important forerunner of the novel. While mainly a fantasy, Buncle does contain fragments of genuine autobiography, including, in the first volume, occasional glimpses of Amory's early life in Dublin. [26] In the most substantial of these, Amory nostalgically recalled The Conniving House, a strangely-named little inn on Sandymount Strand, Dublin, [27] and the company he had enjoyed there during the first half of the 1720s: [28]

> The Conniving House, ... was a little public house, kept by Jack Macklean, about a quarter of a mile beyond Rings-end, on the top of the beach, within a few yards of the sea. ... . Many a delightful evening have I passed in this pretty thatched house with the famous Larrey Grogan, who played on the bag-pipes extreme[ly] well; dear Jack Lattin, matchless on the fiddle, and the most agreeable of companions; ... and many other delightful fellows; who went in the days of their youth to the shades of eternity. ... . [29]

The other musician Amory wrote of was indeed famous - in his own day and long after. One of the Grogans of Johnstown Castle, Rathaspeck, co. Wexford, Larry was born in 1701, the fourth son and sixth child of John Grogan (1653-1720), the first of his line at Johnstown, by his second wife. The piper, who is likely to have been the Laurence Grogan who was admitted attorney of the Court of Exchequer in Dublin in May 1726, also died young, in Barbados, according to family tradition: the will he drew up in May 1728 was proved in the diocese of Ferns in March 1729. Songs were written in Grogan's praise during his lifetime, and he himself composed 'Ally Croker', one of the most popular songs of the eighteenth century. His posthumous fame was such that a racehorse named after him twice won the Galway Plate in the early 1740s. [30]

As Amory appears to have regularly enjoyed the company of the two musicians in The Conniving House, Jack Lattin himself may also have been living in Dublin during the early 1720s. His family, as we have seen, had a town-residence in Latin Court, in the parish of St Michan's. A neighbour of theirs in nearby Capel Street would have been the famous Catholic controversialist, Dr Cornelius Nary (1658-1738), parish-priest of St Michan's from 1699 to 1738, who was a native of Tipper near Naas. Nary had organised a school staffed by Jesuits in the parish, which Jack Lattin could easily have attended. He also officiated at Patrick Lattin's funeral in June 1732, and could have done likewise at Jack's the previous July. [31]

Jack Lattin and Larry Grogan, both gentleman musicians, would have contrasted sharply in backgrounds. Lattin was the heir of an Old English Roman Catholic family, with no native Irish connections, settled in the same place for five hundred years. His family, having weathered the troubles of the seventeenth century, were now facing the uncertainty engendered by the draconian strictures on landholding by Roman Catholics in the recently-enacted Penal Laws. In contrast, the Grogans were Protestant and of native Irish descent. Larry's grandfather Cornelius, a merchant in Wexford town in Cromwellian times, had married a Mary MacDonnell, a relation of the earl of Antrim's. [32] (Randal MacDonnell (1609-83), 2nd earl and 1st marquis of Antrim, lived in Wexford town during the 1640s, which was the base for his privateering operations.) [33] But the Grogans apparently also acquired a Cromwellian connection. Though Larry's father, John, came into the Johnstown Castle estate through marriage with his first wife, Mary Reynolds, in 1682, the forename 'Overstreet' in several generations of their descendants suggests that the family was related to the Colonel Overstreet to whom Johnstown had been granted under the Cromwellian land settlement. [34]

In early eighteenth-century Dublin, however, music-making - at least at a certain level - seems to have crossed sectarian and political divides. Though neither Lattin nor Grogan are known to have been directly linked to it, a convivial, non-sectarian and non-political music society, forerunner of the Charitable Musical Society, met in various taverns around Christchurch. It centred on William and John Neal, father and son, who were music-publishers and sellers in Christchurch Yard, and even encompassed veteran soldiers who had fought on opposing sides in the wars of 1689-91. [35] Given that the Neals were the only music-publishers and sellers in Dublin during the 1720s, they are very likely to have known Grogan and Lattin - if only to the extent of selling fiddle-strings to the latter. But acquainted with the two musicians or not, John Neal was to play a vital part in the perpetuation of their posthumous memory.

## II

While Thomas Amory lamented that his companions in The Conniving House 'went in the days of their youth to the shades of eternity', if he knew anything concerning the manner of Jack Lattin's going he kept silent. [36] To say whether the story of the dance marathon was actually true is difficult: there is always a possibility that it grew up to explain his untimely death. If all that was remembered of Lattin at some point was that he had been an accomplished dancer and had died young, these two facts could have been linked in the popular mind as cause and effect. Dancing, however, did not normally prove fatal; so to account for his death Lattin would have needed to perform some extraordinary, potentially life-threatening feat; hence the story of his marathon.

But while the account of Lattin's marathon is bound to have accrued folklore elements in the course of transmission - his changing his steps every furlong, for instance - the basic story of the fatal dance-marathon does not appear to be a common motif. [37]

Most Irish references to the performance of dances over long distances are not to solo-performances but to the processional rince fada, or 'long dance'. Traditionally danced on May Eve or May Day to welcome summer, this dance survived down to the late eighteenth century and, in some places, until the early nineteenth. [38] In the seventeenth century it was danced, usually by young women carrying a large garland of flowers, as a welcome to important persons. The Duke of Ormonde was thus greeted when he returned from London to Dublin in 1662, having been appointed viceroy of Ireland, as was James II on his progress northward after landing in Youghal, co. Cork, in April 1689. [39] On a local level, the rince fada was also performed to welcome home a landlord to his seat after a long absence, when the performers would weave intricate figures in front of his carriage for miles along the road. [40] Eventually, in the later nineteenth century, the term rince fada came to be used interchangeably with the English term 'country-dance' to designate a longways progressive dance. [41]

The only folklore in anyway reminiscent of the Lattin story are two versions of the one story collected in Derrynane, co. Kerry, earlier this century. The basic story here is that of a man who walks a certain distance, dancing at regular intervals. The more low-key and credible version recounted how a local man, out of an inordinate love of dancing or else for a bet, made a circuit of the four widely-dispersed dancing venues around Derrynane one Sunday afternoon to dance a bout at each. [42] The poet, Tomás Ruadh Ó Súilleabháin, who was also a dancing master, featured in a more grandiose version of the story. Tomás's father, a piper, bet his pipes against �5 that his son was a better dancer than a certain Cork dancing master for whom he was providing music. The father sent for Tomás to Bawnard, near Derrynane, and Tomás was said to have walked to Cork in a Sunday afternoon, dancing a bout at sixteen crossroads on the way. On arrival Tomas gave his challenger the choice of competing against him immediately, or else deferring the contest until the next day. The Corkman chose to stage the contest on the spot, assuming that Tomás would be exhausted after his long walk. Tomás, however, defeated his opponent, but was so crippled with pains and exhaustion that he was unable to rise from his bed for a week afterwards. [43]

In common with the Lattin account these two have the idea of a dancer travelling a certain distance, either dancing, or walking and dancing, to win a wager. However, while the version in which a man perambulates the four dancing venues on the one Sunday afternoon sounds as if it could have happened, Tomás Ruadh Ó Súilleabháin could not have walked from Bawnard to anywhere in co. Cork in a single afternoon. This is not to deny that there is any truth in the account of the dancing-contest: dancing masters were highly competitive and challenges between them were common. Possibly Tomás Ruadh did walk to Cork to accept the challenge of another dancing master, but took a number of days to do so, and the account then attracted the one in which the man made a circuit of the four local dancing venues on the one Sunday afternoon.

On the other hand, the Lattin story could be true, or at least contain a kernel of truth. Outlandish wagers were common in eighteenth-century Ireland, and Lattin's purported feat pales in comparison with those of Thomas 'Buck' Whaley (1766-1800), who once won 2,000 guineas by leaping out a second-floor window and over a waiting mailcoach, and �15,000 by travelling to Jerusalem and back within two years. [44] And there is one famous precedent for Lattin's feat. In 1599 Will Kemp - actor, acrobat, professional dancer and a colleague of Shakespeare's - performed a Morris-dance from London to Norwich (130 miles), making a fortune through betting heavily on himself. The dance took twenty-three days: Kemp danced for nine, accompanied by his musician on the pipe-and-tabor, but fatigue and bad weather compelled him to rest for the other fourteen. Occasionally, a local dancer joined Kemp but none could keep up with him - though a couple of young girls impressed him with their stamina. [45]

Even if Lattin did dance between Morristown and Dublin - the less likely version of the marathon - the distance would have been only five or six miles more than Kemp averaged per day over very poor roads. A moderately-fit person could walk twenty miles a day, while thousands with little or no athletic training successfully complete marathon-races (26.25 miles) every year. But walking or running a long distance would be radically different to dancing it, which is likely to have been the most exhausting. [46] To succeed in dancing the twenty or so miles between Dublin and Morristown, Lattin would need to have been extremely fit and in training, and there is nothing to say that he did not take more than one day to do it. [47]

However, the Mansfield tradition that Lattin danced between Morristown and Castle Browne appears the more likely of the two. Besides having the authority of his brother's descendants behind it, the shorter distance involved, and its very localness, would also argue in its favour. The placename Castle Browne would have meant next to nothing outside the local area, and had, in any event, been replaced in 1814 by Clongowes Wood. We can only speculate as to why Castle Browne should have been one terminus of Lattin's marathon. The Brownes, like the Lattins, were Roman Catholics of Old English descent, and the two families could have socialised together. Perhaps in the course of a night's dining and drinking, one of the Brownes, aware of Lattin's talents, challenged him to dance between their two residences.

The later substitution of Dublin for one terminus of the dance would have been typical of the exaggeration this type of story would undergo in the course of oral transmission. Exaggerated and embroidered versions of feats such as Lattin's were often circulating within a surprisingly short time. Will Kemp published his Nine Daies Wonder to counter the highly exaggerated accounts that began to circulate within days of his reaching Norwich. [48] And Buck Whaley's trip to Jerusalem was the subject of exaggeration during his own lifetime. Even his brother-in-law, Lord Cloncurry, believed that Whaley had wagered he would walk to Jerusalem - as atonement, some wags were to claim, for his multifarious sins - while others had it that the wager was that Whaley would play handball against the walls of the city. [49]

Both Kemp's dance to Norwich and Whaley's trip to Jerusalem were sensations in their day. The former records the crowds that turned out to greet him and the official welcomes he got in various towns and villages on his route, the largest of which was, naturally, in Norwich. Fashionable Dublin turned out in force to see Whaley off in 1788, an event celebrated in a contemporary song, 'Buck Whaley's Departure', while his triumphant return is said to have inspired the song (and jig), 'Round the World for Sport'. [50] Perhaps Lattin's marathon was a minor version of these large-scale events, with friends and backers escorting him along the road, and a musician accompanying him. The 1807 account, though, claimed that Lattin had accompanied himself - though wrongly on the bagpipes. [51] 'Matchless on the fiddle' as he was, Lattin could have supplied his own music: dancing and playing simultaneously - though not for miles along a road - was a party-piece with later fiddlers, including the legendary Sligo player Michael Coleman (1893-1945). [52]

All the accounts agree that Lattin finished his dance and won his bet, and that he died subsequently - after some days, according to one. This suggests that his exertions exacerbated an underlying health problem, or else so weakened him that he contracted one of the numerous fevers endemic in the early eighteenth century. Despite Lattin's youth and physical fitness, the marathon dance would have imposed a tremendous physiological strain on him. He was also dancing at the height of summer, and one possible cause of his death was that while he consumed sufficient fluid to replace that which he was sweating, he neglected to take in enough salt as well. This would have caused an imbalance of electrolytes, resulting in a gradual swelling of the brain, increasing drowsiness, and a lapse into coma over a day or two. [53]

## III

Though P.W. Joyce's 1910 notice of Jack Lattin was incorrect in some details, he did draw attention to Lattin's most enduring claim on posterity:

> It is worthy of remark that there is a well-known Irish tune called 'Jack Lattin,' which some of our Scotch friends have quietly appropriated; and not only that, but have turned Jack himself into a Scotchman by calling the tune 'Jockey Latin'. [54]

Joyce did not exaggerate when describing the appropriation of the tune 'Jack Lattin', and long before he wrote, the tune had become so deeply embedded in the musical tradition of the Scottish Borders (which also covered the northern counties of England), that it was considered to be native to the area. The predominant musical instruments in this area would have been the fiddle, the bellows-blown Border bagpipe, and the smallpipe, also bellows-blown. Both these pipes were to die out in the nineteenth century, as the mouth-blown Highland pipe established itself throughout Scotland as the country's national instrument. The smallpipe, though, survived and evolved south of the Border in Northumberland, and recent years have seen a revival of interest in the Border pipes. The degree to which 'Jack Lattin' penetrated into this musical tradition can be gauged from the way in which Sir Walter Scott invoked it to fill out a picture of a typical Border piper of the 1680s. In 'Wandering Willie's Tale', set in the 1740s, the eponymous narrator describes his grandfather as 'a rambling, rattling chiel' who 'could play weel on the pipes', whom 'a' Cumberland couldna touch ... at "Jockie Lattin" ...'. [55]

The earliest datable mention of 'Jack Lattin' occurs in Faulkner's Dublin Journal, 2-5 June 1733:

> At Madame Violante's Booth in George's-Lane, on Wednesday next, being the 6th of June, 1733, for the benefit of Mr. Walsh and Mr. Cummins, Dancing-masters, will be performed a Grotesque Opera, call'd Harlequin Triumphant; or the Father deceiv'd; a new Entertainment call'd the Tavern Bilkers; with the humourous Farce of Scapin; with Variety of Dancing and Musick, particularly several Concerto's on the Harp, and Jack-Latin on the Pipes, by two of the best Masters in this Kingdom. [56]

The specific mention of 'Jack Lattin' in this advertisement may indicate that it was a hit that year, and also implies that it had been circulating for some time. But more intriguingly, besides this tune, one named for Lattin's musical colleague in The Conniving House, Larry Grogan, was also popular in Dublin in the 1730s. In a poem published in 1740, 'A Dissertation on Italian, and Irish Musick' by Laurence Whyte (1680-1752), an enthusiastic member of the musical society centering on the Neals, a bucolic country squire, bemused by the new-fangled 'Italian musick', finally loses patience and demands that the musicians play his favourite tunes:

> ... damn you! play up the Black Joke,
> Or else you'll get your Fiddles broke,
> Then play Jack Lattin, my dear Honey!
> Hey! Larry Grogan for my Money! [57]

Two early examples of the tune 'Jack Lattin'.

![The Tune Jack Lattin](lattin4.jpg "The Tune Jack Lattin")
Example 1. 'Jack Latin [sic], from John Neal's third collection of country dances - exact title unknown (Dublin, 1734), p. 15.

![The Tune Jack Leighton](lattin5.jpg "The Tune Jack Leighton")
Example 2. 'Jack Leighton', from Drummond Castle MS (1734-1940), in possession of the Earl of Ancaster, Drummond Castle, Crieff, Scotland (Xerox copy in National Library of Scotland, NLS MS Acc. 7722), p. 4.

Both tunes were published in late 1734. John Neal advertised his third collection of country dances in Faulkner's Dublin Journal, 12-16 November 1734, and Dublin Evening Post, 16 November 1734, and the list of fifty-six dances includes 'Jack Latin' [sic] and 'Lary Grogan'[sic]. [58] No copy of Neal's third collection of country dances was hitherto thought to be extant. Of the five country-dance collections John Neal published between 1726 and 1739 - some in collaboration with his son William - the only known survivor was a copy of the first (1726), now in the National Library of Ireland. [59] But there is also a hitherto-unidentified copy of the third Neal collection in the National Library of Ireland. Missing its title page but otherwise complete, the book is stated in a manuscript-note on page 1 to a collection of 'Scotch country-dances' popular in Dublin during 1736. [60] The format and typography is identical to the Neal 1726 collection, and the titles of the dances agree, except for one, with those listed in the newspaper advertisements. [61] The manuscript description of the collection as containing 'Scotch country dances' is, for the most part, true: fifty-three of the fifty-six dances are taken directly from John Walsh, Caledonian country dances (London, 1733). [62] The remaining three tunes are 'The Humours of Trim' (p. 17), a double-jig with a very classical feel to it; 'Lary Grogan' (p. 3), another double-jig, and 'Jack Latin' (p.15), a reel with three parts.

The two tunes are also unique in being the earliest examples of dance-tunes with Irish associations to carry the names of known musicians. The coincidence of their being named after musicians who were friends and played together suggests that they derived from a common source. The 1807 account of Jack Lattin claimed that he not only composed the tune named after him, but also danced to it on his marathon. [63] However, given the form of their titles, a third party is more likely to have composed and named 'Jack Lattin' and 'Larry Grogan': musician-composers do not, as a rule, call tunes after themselves. Nor are these tunes likely to have been commemorative.

The particular mention of 'Jack Lattin' in the newspaper-advertisement of late May 1733 implies that it was already well-known in Dublin, and to suggest that it could date from Lattin's own lifetime would not stretch the bounds of credibility unduly. A song in praise of Larry Grogan, written in his own lifetime but not published until c. 1750, was sung to the jig named after him, which could imply that the tune was composed before 1728. [64] In the course of the song, Grogan is said to have played a jig called 'Lanstrum's Pony', the title of a tune published in Dublin in 1726, the second part of which shares some bars with the corresponding strain of 'Larry Grogan'. [65]


'Jack Lattin' became enormously popular within a very short time. On 21 June 1736 and again on 30 June it was danced in Smock Alley Theatre, Dublin, the first time solo by a man, the second time by a couple. [66] In a recital on the musical glasses in the same theatre on 3 May 1743, the luckless Richard Pockrich (c. 1695-1759), inventor (or possibly re-discoverer) of this instrument, played 'Eileen-a-roon', 'The Black Joke' and 'Jack Lattin', as part of a programme classical and popular music. [67] Another exotic musician to play 'Jack Lattin' was Isaac Isaacs, a German-born performer on the hammered dulcimer who died in Dublin on 4 May 1791, aged over eighty, having lived in the city since 1767-8. [68] 'Jack Lattin' was equally popular with ordinary musicians. Writing on 22 August 1786, the Revd William Beauford of Athy, co. Kildare, a contributor to Joseph Cooper Walker's work, The historical memoirs of the Irish bards ... (2 vols., Dublin, 1786), remarked that 'the Pipers in Ossory' played a tune that 'much resembles Jack Latin' for the 'Rincky fada', thus implying that the tune was a familiar one. [69] The tune was still common in the early decades of the nineteenth century, and was said in 1807 to be 'as great a favourite in Ireland as the Timrodee in Otaheite' [70] During the same period, Dublin music-publishers such as Gough, Hime and Rhames, were issuing 'Jack Lattin - A Favourite Irish Air with Variations' arranged for various musical instruments. In 1804 the famous Irish piper O'Farrell, a native of Clonmel, co. Tipperary, then pursuing a career on the London stage, published his tutor and collection of music 'for the Irish or Union Pipes', and included 'Jack Latten with Variations', nicely arranged for his instrument and qualifying it as 'Irish'. [71]

By O'Farrell's time, though, most Scottish musicians would have disputed his claim that 'Jack Lattin' was Irish. Within a surprisingly short time of its first appearance in Dublin the tune was finding its way into Scottish collections. In printed collections published in London in 1737, [72] 1749 [73] and in the late 1750s, [74] the title is 'Jack Latin', and there seems to be little doubt that the ultimate source here was the 1734 Neal collection. A four-part setting close to the printed versions occurs as 'Jack Leighton' in the Drummond Castle MS (1734-1740). [75] But a different version of tune appeared as 'Jake Lattine' in the manuscript collection of music for the Border bagpipe William Dixon compiled between 1733 and 1738. [76] Other changes rung on the title are 'Jackie Latten' (1768), [77] 'Jack-a-Latton' and 'Jack o' Latain' (1774-5). [78] In an 1804 collection for the Northumbrian smallpipes the tune is 'Jacky Layton', [79] and a Gaelic title took primacy in an 1848 collection for the Highland pipes: 'Cuir do chuid air fire faire. The Graceful Move (Jockey Lattin)'. [80] The changing form of the title has occasionally puzzled writers, leading them to suggest that it was a corruption of an earlier one. [81] The tune is ubiquitous in other Scottish collections of the period and later, and turns up in many modern collections of dance-music for various instruments. 'Jenny Latin', a reel published around the beginning of this century, bears a vague resemblance, hence the title, probably. [82] Scottish and Northumbrian musicians greatly developed 'Jack Lattin' through adding further variations to the original three strains, and versions are found with anything from nine or ten parts up to twenty-three. [83]

But in Ireland 'Jack Lattin' appears to have faded from popularity in most parts as the nineteenth century progressed, and no collector of Irish dance-music over the last century and a half recorded the usual version from a live performance. [84] However, a number of related tunes are to be found in manuscript and printed collections. 'Jenny Rock the Cradle', in a mid-nineteenth century manuscript collection, has the same first strain as 'Jack Lattin', though a different second strain. [85] The title 'Jenny Rock the Cradle' was also borne by a different tune, more distantly related to the original 'Jack Lattin', published in 1903 by Francis O'Neill (1847-1936), one of the great collectors of Irish dance music. [86] In 1907 O'Neill altered the title of this tune to 'Jacky Latin', [87] doing so at the behest of 'a musical acquaintance' who insisted that this was the proper title. [88] The only point of direct resemblance O'Neill's tune has with the original is the second strain: otherwise, to paraphrase a famous jazz musician, it was a case of 'same notes, different tune'.

Besides the versions just mentioned, a number of elaborate settings deriving from published Scottish collections are also to be found in Irish manuscripts of the nineteenth century. [89] It is obvious from O'Neill's spelling of the title that his 'musical acquaintance' was familiar with the Scottish versions of the tune, and it does appear that these were the ones current in Ulster in the late nineteenth and early twentieth centuries. [90] Around 1898, for instance, Philip Goodman, a professional piper from Farney, co. Monaghan, dictated the titles of all the tunes he could remember to the Irish scholar, Henry Morris, and among his reels was 'Lock o Latin', an obvious slip (on Morris's part?) for 'Jock o' Latin'. [91] O'Neill also published a co. Monaghan tradition that attributed the tune to the famous eighteenth-century composer, 'Piper' Jackson. The subject was claimed to have been a Jack Duffy from the townland of Lattan, near Aughnamullen:

Duffy being a fine strapping young man, a local Adonis, and an incomparable dancer in those days when dancing was a fine art, he won Jackson's friendship and esteem to such an extent that the great composed immortalised him in the beautiful tune 'Jack o' Lattan.' [92]

Though this story obviously grew up to explain the Scottish version of the title, it is interesting that the subject was thought to have been a great dancer. Hundreds of tunes were attributed to the piper-composer Walker Jackson (d. 1798) in his own lifetime and long after. He is documented as living at Lisduan, Ballingarry, co. Limerick, but northern tradition attributed all 'Jackson' tunes, especially reels, to Hugh Jackson of Creeve, Ballybay, co. Monaghan, a celebrated owner of racehorses. This confusion, already current in the lifetime of the Limerick Jackson, has never been satisfactorily resolved. [93]

The Jackson-attribution persists in the fiddle-tradition of co. Donegal, in which a good number of 'Jackson' tunes (principally reels) are still played. The late Francie and Mickey Byrne, on a recently-released cassette-recording, play a reel which they called 'Jackson's Reel' in English, but 'Seán Ó Laidin' in Irish, an obvious translation (by the producers of the tape?) of 'Jack o' Latin'. [94] The tune in question is actually best-known as 'The Pinch of Snuff' and, like O'Neill's 'Jenny Rock the Cradle', belongs to same tune-family as 'Jack Lattin'. [95] The late John Doherty, the legendary Donegal fiddler, also called this tune 'Jackson's reel', but not 'Jack Lattin'. However, some younger Donegal fiddlers who knew or were influenced by the Byrnes have adopted their titles for this reel. [96]

## IV

The reason why 'Jack Lattin' assimilated so easily into Scottish tradition lies in the nature of the tune itself. As a jig 'Larry Grogan' belonged to a genre well-established in Irish tradition by the early 1700s, and which became so strongly identified with Ireland that the phrase 'Irish jig' has long been a cliché. The jig, however, originated in England, where it was of northern or Scottish origin, spreading to other European countries, including Ireland, in the course of the seventeenth century. [97] But 'Jack Lattin' is a reel, a dance and tune-type native to Scotland, not widely found in Ireland or England before the last decades of the eighteenth century. [98]

The term 'reel' is found as early as the sixteenth-century in Scottish sources, but the dance itself only began to gain in popularity with the revival of social dancing in Lowland Scotland around 1700. [99] By the 1780s and 90s, the reel had become a fashionable ballroom-dance in London, and Dublin music-publishers began reprinting the most popular reels in the small collections of country dances they issued. Within a short time reels with Irish titles, or else named after fashionable patrons in Dublin, were appearing in these collections; but even today many of the classic and most popular reels are Scottish tunes first published in Ireland in the period 1780-1800. [100]

Given that reels passed from Scotland to Ireland - though sometimes via London - it is perfectly understandable how 'Jack Lattin' came to be assumed to have been Scottish. The disappearance of Irish versions of the tune, and their replacement, especially in the north of Ireland, by Scottish versions, would also have reinforced the assumption of a Scottish origin. The tune, nevertheless, may not have been as egregious in Ireland of the 1730s as would first appear. While not widely popular in most of Ireland before the late eighteenth century, reels may not have been completely unknown. Turlough O'Carolan (c. 1670-1738), for example, produced tunes in reel-measure, one of several European dance-metres he used, though these are not dance-tunes as such. [101] In Dublin of the 1730s, Scottish tunes, including reels, were regarded as typical of Ulster (as we might expect), [102] and the occasional reel or reel-type tune with Irish associations is to be found in London dance-collections from the 1730s to the 1760s. [103] And while people in Dublin may not have been dancing Irish reels during the 1730s, they were certainly dancing Scottish ones. The Neals, after all, were taking the bulk of the country-dances in their own collections, reels included, from previously-published Scottish collections and, as we have seen, their 1734 collection is actually described as a collection of 'Scotch country-dances' popular in Dublin during 1736. [104] 'Scots/Scotch' dances were also frequent on stage in Dublin during the 1720s and 30s, outnumbering any ostensibly 'Irish' ones. [105]

Besides the preceding, there were substantial musical links between Dublin and Edinburgh in that several classical musicians are known to have passed back and forth between the two cities in the 1720s and 30s. At this period, the divisions between different musical genres were not so rigid, and professional musicians, besides performing and teaching the 'Italian musick', often injected local colour to their performances by arranging and playing Scottish and Irish folk-tunes. Any classical singer appearing in Dublin, for instance, was expected to sing 'Eileen-a-roon' - and in Irish too. [106] These musicians could easily have carried tunes and tune-types between the two cities. The cellist Lorenzo Bocchi, who arrived in Edinburgh in 1720, was in Dublin by 1724. He arranged Carolan's 'Pléaráca na Ruarcach' for the Neals' Celebrated Irish tunes, and in August that year, the Neals published his 'Scotch Cantata' and a number of his sonatas. By 1729 Bocchi was back in Edinburgh, giving concerts and being entertained by the Edinburgh Musical Society. [107] Another Italian musician, Francesco Barsanti (1690-1772), who lived in Edinburgh from 1735 to 1743 and published arrangements of thirty Scots folksongs in 1742, [108] visited Dublin between 1735 and 1740, bringing Laurence Whyte a collection of poetry from Scottish poet Allan Ramsay (1686-1758). [109] Whyte also names a 'Mac---n' as the composer a set of 'divisions' (variations) on 'The Black Joke' [110] - probably the set Neal advertised for sale in Faulkner's Dublin Journal, 17 November 1730 - and this is likely to have been the Scottish violinist-composer Charles Maclean. [111]

Also in 1729, along with Bocchi, the Edinburgh Musical Society entertained the brilliant English violinist, Matthew Dubourg (1707-67), Master of the State Music in Ireland, 1728-52, whose influence and teaching were responsible for high standard of string-playing in Dublin which so impressed Handel on his 1743 visit. [112] Whyte commented on Dubourg's tasteful playing of Irish airs - though the only arrangement he actually published was 'Eileen-a-roon' for the harpsichord in 1746. [113] According to a posthumous account Dubourg was also fond of attending fairs near Dublin disguised as a country-fiddler. On one such occasion, at Dunboyne, co. Meath, his brilliance attracted such an audience that the other performers, jealous and out of pocket, ganged up and assaulted him so violently that he was fortunate to escape with his life. [114]

All these musicians were associated with the Dublin music society and the Neals, and while there is no evidence to link either Larry Grogan or Jack Lattin with them or the society itself, it is very likely that the tunes named for the two originated among the members of the society. Whatever about the original composer, John Neal's immediate source for both 'Larry Grogan' and 'Jack Lattin' was almost certainly a fiddler. Though 'Larry Grogan' is a quintessential Irish pipe-jig - as its subsequent popularity was to prove [115] - Neal's setting is pitched a tone higher than usual, in A major, a key ideally suited to the fiddle but not to the Irish pipes, and it also contains note-figures typical of the fiddle rather than the pipes. In contrast, the setting that John Walsh published of this tune in 1737 is at the normal pitch for the Irish pipes, and lacks many of the tricky note-figures of Neal's. [116] And while 'Jack Lattin' became strongly associated with various types of bagpipes, it is in essence a fiddle-tune, containing passages that would have been easily executed on the violin, but not particularly well-suited to other instruments.

In Thomas Amory's opinion, Jack Lattin, though only a youth, was 'matchless on the fiddle', and this raises the possibility that he was a pupil of one of the musicians just mentioned. Grogan was also an excellent performer on his instrument according to tradition. Whoever composed the tunes named after the two musicians may have intended them to match the instruments each played. Jigs of various types are typical of most bagpipe traditions in Western Europe, so such a tune would be appropriate for Grogan. A fast lively reel, typically fiddle-music, would have been apt for Lattin, who could have also danced reels.

## V

Most old dance-tunes have jingles or odd verses sung to them, or have become the airs of songs, and 'Jack Lattin' was no exception. Most of these tunes and verses were Scottish. 'Jacky Lattin's Courtship', commencing 'There was a lad just come from France', was published on a single sheet in the 1730s, [117] and republished with several other songs in the early nineteenth century as 'Jack-a-Latton's Courtship'. [118] Robbie Burns (1759-96) used the tune for 'The Lass of Ecclefechan' [119] and the bawdy, 'O Gat ye me wi' Naething'. [120] A stray verse to the tune is to be found in the Scottish St Clair MS of c.1781-5:

> There was a pretty maid
> And she was dressed in satin, ...
> And she sat upon the ground
> Cried - 'Kiss me Jacky Latin.
> Kiss me Jacky, kiss me Jacky,
> Kiss me Jacky Latin!
> Won't you kiss your pretty maid,
> Altho' she's dress'd in satin?' [121]

In Northumberland osculation and satin again featured in a rhyme, but this time Jack went unkissed:

> Bonie Jockie, Braw Jockie,
> Bonie Jockie Latin
> Because she wudna' gie'm a kiss,
> His heart was at the breaking.
> Bonie Jockie, Braw Jockie,
> Bonie Jockie Latin.
> His skin was like the silk sae fine,
> And mine was like the satin. [122]

The Mansfield family-rhyme about Jack Lattin's dancing from Morristown to Castle Browne, quoted above, may once have been sung to 'Jack Lattin' as well, but the form recorded in the late nineteenth century does not fit the tune. However, the first two lines of the quatrain could be made to fit the tune by inserting a monosyllabic word at the start: 'Young Jack Lattin dressed in satin, / Broke his heart of dancing, ...'. The second and third lines, though - 'He danced from Morristown / To Castle Browne' - are metrically faulty as they stand, and would require radical emendation to fit the tune. If the rhyme was once sung to 'Jack Lattin', the tune must have been forgotten at some point, with the result that the metre of the quatrain became corrupt.

That verses about Jack Lattin sung to the tune named after him were current at some stage is hinted at in a rhyme that children in Tramore, co. Waterford, used to sing as they went around at Halloween down to the 1960s and 70s. The air here was 'Yankee Doodle Dandy' - a variant of a tune known in Ireland as 'I wish I had a Kerry Cow' and as 'Is trua gan peata an mhaoir agam' [123] - but the original could have been 'Jack Lattin' itself:

> Old Jack Lattin, silk and satin,
> Give the poor man something;
> A piece of bread, a roasted egg,
> An apple or a dumpling.

> Óró the dog is dead,&nbsp;&nbsp;
> Óró the dog is dead,&nbsp;&nbsp;
> Óró the dog is dead,&nbsp;&nbsp;
> He died last night with a pain in his head. [124]&nbsp;&nbsp;

## VI

While the memory of Jack Lattin and his dance was still alive in the folklore of his native co. Kildare at the turn of the last century, as P.W. Joyce again noted in 1910, one aspect took an unpleasant form:

> 'I'll make you dance Jack Lattin' - a threat of chastisement often heard in Kildare. .. . 'I'll make you dance' is a common threat heard everywhere: but 'I'll make dance Jack Lattin' is ten times worse - 'I'll make you dance excessively.' [125]

Joyce understood the threat to refer to Lattin's dance-marathon: the victim would dance, with pain, as much as Lattin did. But while the phrase carries an implicit allusion to the dance-marathon, it can only refer directly to the dance 'Jack Lattin'. If the phrase took the form 'I'll make you dance like Jack Lattin', it would agree with Joyce's interpretation; but the direct object of the verb 'to dance' can only be a particular dance.

The use of a lively dance as a metaphor for a beating is not that unusual: many an exasperated parent has promised to make a disobedient child dance the 'Highland Fling', or 'The Wind that Shakes the Barley'. Such phrases, coined in the heat of the moment, rarely find their way into oral tradition or literature. A notable exception is 'Trenchmore', a boisterious English country-dance of the sixteenth and seventeenth centuries - probably of Irish origin - which came to be used of rapid, often agitated and involuntary movement. [126] 'A dance of Trenchmore at the whipping crosse' is how Will Kemp described the public flogging of two cut-purses caught stealing from the crowds gathered to watch him dance through Reading on his marathon. [127] In contrast, 'to dance Barnaby', an English proverb recorded in the seventeenth century meant to celebrate and enjoy oneself. [128]

Since 'Jack Lattin' was a reel in an era before reels were widely-known and danced in most of Ireland, a casual observer would have found it faster and livelier than other dances. [129] The jig and hornpipe would have had more complex footwork, especially in the early nineteenth century, but were performed, often solo, at a more stately pace than a reel. Queen Victoria, for instance, noticed the contrast between an Irish jig and a Scottish reel during her visit to Carton, co. Kildare, on 10 August 1849. Having watched some of the Duke of Leinster's tenants performing an Irish jig, the queen later wrote that the dance had been 'quite different to the Scotch reel; not so animated, and the steps different, but very droll.' [130]

'I'll make you dance Jack Lattin' had a further Joycean connection in that James Joyce (1882-1941), quoted it in Ulysses. In the course of a sadomasochistic fantasy in the Circe episode, set in the Phoenix Park, a number of elegantly-dressed Dublin society ladies - 'The Honourable Mrs Mervyn Tallboys', 'Mrs Bellingham', and 'Mrs Yelverton Barry' among them - gather to punish Leopold Bloom for sending them anonymous obscene postcards. As 'The Honourable Mrs Mervyn Tallboys' prepares to set about the quivering Bloom with her riding crop, 'Mrs Bellingham' eggs her on:

> Tan his breech well, the upstart! Write the stars and stripes on it! ... Make him smart, Hanna dear. Give him ginger. Thrash the mongrel within an inch of his life. The cat-o'-nine tails. Geld him. Vivisect him. [131]

But in delivering colourful threats 'The Honourable Mrs Mervyn Tallboys' is equal to her friend:

> I'll scourge the pigeonlivered cur as long as I can stand over him. ... I'll flog him black and blue in the public streets. I'll dig my spurs into him to the rowel.

Addressing the quivering Bloom she promises:

> I'll make it hot for you. I'll make you dance Jack Latten ... . [132]

Joyce had a tenuous connection with Jack Lattin in that he boarded at Clongowes Wood College, the Castle Browne of Lattin's time, between 1888 and 1891. [133] While subsequently attending Belvedere College as a day-pupil, it was at Clongowes that Joyce first imbibed the Jesuit teaching and philosophy that influenced him so much that he could later say that he was not so much a Roman Catholic as a Jesuit. [134] Joyce could possibly have heard 'I'll make you dance Jack Lattin' at Clongowes Wood: the Jack Lattin story was still current during the 1880s and 90s, and Joyce was aware of the legends and ghost-stories attached to the Wogan-Browne castle at Clongowes Wood. [135] He is more likely, however, to have found the phrase in P.W. Joyce's book, since he used it precisely in the sense given there. [136]

## ACKNOWLEDGEMENTS

I am very grateful for information and help in various ways to Anna Bale, Folkmusic Division, Dept. of Irish Folklore, University College, Dublin; Nicholas Carolan and the staff of the Irish Traditional Music Archive; Joe Crane, Newcastle-upon-Tyne; Colm de Brún, Dublin; Philomena Donnelly, St James' Hospital, Dublin; John Fry, The Dublin City Morrismen; Pier Kuipers, Dave Curran Design; Terry Moylan, Brooks' Academy; Bairbre ní Fhloinn, Dept of Irish Folklore, University College, Dublin; Jimmy O'Brien Moran, Tramore, co. Waterford; Jerry O'Reilly, Brooks' Academy; Matt Seattle, Dragon Fly Music; Gerry Starr, Na Píobairí Uilleann; Edward Walsh, Morristown Lattin Stud.

REFERENCES
[1] For the Lattin family and their associations with Naas and St David's, see John M. Thunder, 'Notices of the family of Lattin', Journal of the Royal Society of Antiquaries of Ireland xviii (1887-8), 183-88; [Anon.], 'The Lattin and Mansfield families of the co. Kildare', Journal of the County Kildare Archaeological Society iii (1899-1902), 186-90, especially the genealogical table following p. 190, which was probably based on one drawn up in connection with a confirmation and regrant of arms to the head of the family in 1763 (Analecta Hibernica xx (1958), p. 119; Genealogical Office [GO], National Library of Ireland [NLI], MS 103, pp 90-1); Thomas J. De Burgh, 'Ancient Naas', Journal of the County Kildare Archaeological Society ii (1891-9), 177.

[2] Kevin Whelan, 'The regional impact of Irish catholicism, 1700-1850', in William J. Smith and Kevin Whelan (eds.), Common Ground: Essays on the historical geography of Ireland presented to T. Jones Hughes (Cork, 1983), p. 257.

[3] Charles Chenevix Trench, Grace's Card: Irish catholic landlords, 1690-1700 (Cork and Dublin, 1997), pp 10, 66.

[4] Margaret Sheehy, 'Morristown Lattin', Journal of the County Kildare Archaeological Society xiv (1964-70), 169-73.

[5] On 7 May 1696, James, son of the late William Lattin and Letitia, his widow, was interred in the crypt of St Michan's Church, Dublin. Letitia Sillyard was William's second wife; his first Begnet Ball died in 1678 (Henry F. Berry (ed.), The registers of the church of St Michan, Dublin, 1636-1700 (Dublin, 1909), p. 418.

[6] Latin Court is marked on John Roque, An exact survey of the city and suburbs of Dublin, 1756; by 1776 it and Cow Lane had been renamed Greek Street (C.T. M'Cready, Dublin street names dated and explained (Dublin, 1892; rep., Dublin 1987), p. 56).

[7] NLI Reports on Private Collections no. 11, p. 277; NLI MS 9637, passim; MS 9649, p. 17.

[8] Even as late as 1765, the marriage of Richard Talbot, heir to the lordship of Malahide, co. Dublin, to Margaret O'Reilly of Ballinlough, co. Westmeath, displeased older members of his family, who were proud of having never mixed their blood with that of the native Irish (Joseph Byrne, War and Peace: the survival of the Talbots of Malahide, 1641-1671 (Dublin, 1997), pp 28-30.

[9] 'Lattin and Mansfield families' [genealogy].

[10] The inscriptions on the tombstone are transcribed in Dennis Allan, 'St David's Church, Naas - a brief history', Irish Family History ix (1993), 46.

[11] Thunder, 'Notices', 186; Richard Hayes, Irish swordsmen of France (Dublin, 1941), pp 23, 27-8, 288.

[12] Thunder, 'Notices', 184, 186-7.

[13] Information from Gerry MacCarthy, FÁS, Newbridge, co. Kildare.

[14] John Burke, The commoners of Great Britain and Ireland (4 vols., London, 1836), III, pp 575-6; Analecta Hibernica xx (1958), p. 111.

[15] When the Mansfield Papers, then in the possession of P.L. Mansfield, The Red House, Newbridge, co. Kildare, were catalogued in the 1950s, they included a number of family letters dating from the early eighteenth century that were not among them when they passed to the National Library of Ireland (Analecta Hibernica xx (1958), p. 105). Sheehy, 'Morristown Lattin', 169, used a manuscript history of the Mansfields (and presumably the Lattins) in the possession of 'Mr. Mansfield', the then owner of Morristown Lattin, compiled by his grandfather.

[16] In the late 1880s Thom's Dublin Directory does not list any Mansfield for Earlsfort Terrace, though a 'Miss M Mansfield' is given as living in Raheny. Had she been born in Morristown, Maude Mansfield would have been a daughter of George Patrick Lattin Mansfield (1820-89), eldest surviving son of Alexander Mansfield and Pauline Lattin, by Mary Frances Constancia O'Kelly. She could equally well have been his niece: George had four other surviving brothers ('Lattin and Mansfield Familes', 190).

[17] NLI MS 2983 [P W Joyce Music Collection] (ii), p. 250. Joyce used the manuscript throughout the late nineteenth century and the early twentieth, copying into it tunes and airs from MSS he received on loan. The note may date from around the 1880s seems likely: while Joyce does refer to Thunder's 1888 article on the Lattins, the reference appears to be an addition to the account he originally wrote. Originally Joyce had Lattin dancing from Morristown to Castle Browne - as does Thunder ('Notices', 187) - but for some reason reversed the direction. He overlooked this note in an account of Jack Lattin he published in 1910 (Note 21 below).

[18] Matthew Devitt, 'Clongowes Wood', Journal of the County Kildare Archaeological Society iii (1899-1902), 213, 215.

[19] 'Tatler' [Edward O'Toole, Rathvilly, co. Carlow], in The Nationalist and Leinster Times (Carlow), 12 January 1929. While quoting from memory, 'Tatler' said that the Lattin story was to be found in Michael Brophy, Carlow past and present (Carlow, 1888), but this is incorrect. I wish to thank Bairbre ní Fhloinn, Dept. of Irish Folklore, University College Dublin, for this reference.

[20] The Panorama; or a journey to Munster (Dublin, 1807), pp viii-ix.

[21] English as we speak it in Ireland (Dublin, 1910), pp 172-3.

[22] W. Hepworth Dixon (ed.), Lady Morgan's memoirs: autobiography, diaries and letters (2 vols, London, 1862), II, pp 297-8, 300.

[23] Personal recollections of the life and times of Valentine Lord Cloncurry, ... (Dublin, 1849), pp 14, 174-6.

[24] Lady Morgan, The book of the boudoir (2 vols, London, 1829) I, pp 49-50.

[25] DNB I, p. 365; Bernard Share, Bunratty: the rebirth of a castle (Tralee, 1986), p. 66

[26] Buncle I, pp 102-3.

[27] The inn stood where the modern Seafort Avenue West now meets the shore (Weston St John Joyce, The neighbourhood of Dublin (Dublin, 1912), p. 18). 'Conneiving House' is marked on Roque, An exact survey ... of Dublin, 1756.

[28] In John Buncle I, pp 87- 8, Amory states that he left Ireland, for good, in May 1725. However, in a letter of 8 July 1773, he states that he was present at a dinner in co. Tipperary 'above thirty years ago' (Notes and queries i, 11(Jan.- June 1855), 58.

[29] Buncle I, pp 87-8 (n10).

[30] Seán Donnelly, 'A Wexford gentleman piper: "Famous Larry Grogan" (1701-1728/9', Journal of the Wexford Historical Society xvi (1996-7), 40-65.

[31] Patrick Fagan, Dublin's turbulent priest - Cornelius Nary, 1658-1738 (Dublin, 1991), pp 32, 43, 47; NLI MS 9637 passim.

[32] NLI GO MS 164, p. 217.

[33] Jane H. Ohlmeyer, Civil war and restoration in the three Stuart kingdoms: the career of Randal MacDonnell, Marquis of Antrim, 1609-1683 (Cambridge, 1993), pp 166-7.

[34] P.H. Hore, History of the town and county of Wexford (6 vols., London, 1900-11), I, p. 348; NLI GO MS 164, pp 217-18.

[35] John and William Neal, A collection of the most celebrated Irish tunes proper for the violin, German flute or hautboy (Dublin, 1724). Facsimile ed. with intro. and notes by Nicholas Carolan (Dublin, 1987), pp xiii-xxv.

[36] The few available Dublin newspapers for June / July 1731 carried no notice of Lattin's death.

[37] For the various dancing-motifs in folklore, see Stith Thompson (ed.), A motif index of folk literature (7 vols., Indiana, 1955-7), VII, pp 187-8.

[38] Breandán Breathnach, Dancing in Ireland (Miltown Malbay, 1983), pp 21-2.

[39] ibid.; A full and true account of the landing and reception of the late King James at Kinsale, ... in a letter from Bristol, April 1st 1689 (London, 1689), p. 2.

[40] Panorama; ..., p. 60; 'The autobiography of Pole Cosby of Stradbally in The Queen's County', Journal of the County Kildare Archaeological Society v (1906-8), 168, 176.

[41] Joan Flett, Social dancing in England from the 17th century. Vaughan Williams Memorial Library Leaflet no. 18 (London, n.d. [c. 1985]), p. 3; Domhnall Ó Súilleabháin (eag.), 'Seanchas Bhordóinín', Béaloideas xvi (1945), 60; Seán Ó Dubhda (eag.), 'Seanchas Beirte', Béaloideas xxiv (1953), 32; J.G. O'Keefe and Art O'Brien (eds.), Rince Gaolach: a handbook of Irish dances (London, 1912), pp 95-101.

[42] Ó Súilleabháin, 'Seanchas Bhordóinín', 60.

[43] Séamus Ó Duilearga (eag.), 'Cnuasach andeas', Béaloideas xxix (1963), 111-12.

[44] Edward Sullivan (ed.), Buck Whaley's memoirs, including his trip to Jerusalem, ... (London, 1906), pp xviii, 34-5, 326-7.

[45] Kemps Nine Daies Wonder: Performed in a daunce from London to Norwich (London, c. 1600). Ed. with intro and notes by Alexander Dyce, Camden Society OS XI (London, 1840), passim.

[46] I wish to thank Jerry O'Reilly for discussing this point with me.

[47] Some years ago, the Dublin City Morrismen danced from Dublin to Kilkenny (approx. 70 miles) over three days. Dancing in relays and taking regular breaks, the Morrismen averaged twenty three miles a day. My thanks to John Fry for this information.

[48] Kemps Nine daies wonder, pp vi, 1.

[49] Sullivan, Buck Whaley's memoirs, p. xvii; Mary Lyons (ed.), The memoirs of Mrs Leeson, madam, 1727-97 (Dublin, 1995), p. 175.

[50] Sullivan, Buck Whaley's memoirs, pp xiii-xviii, xxxi-xxxiii, xxxv, 160.

[51] Panorama; ... , p. viii.

[52] Harry Bradshaw, Micheal Coleman 1891-1945 (Dublin, 1991), p. 39; Jackie Small (eag.), Ceol rince na hÉireann IV (Baile Átha Cliath, 1997), lch. 109.

[53] I am indebted to Philomena Donnelly for this suggestion.

[54] English as we speak it in Ireland, p. 173.

[55] Redgauntlet: a tale of the eighteenth century. The Waverly Novels, Dryburgh Edition XVIII (London and Edinburgh, 1894), p. 103.

[56] George's Lane is now South Great George's Street. For the fairly notorious Madame Violante, see Brian Boydell, 'Jonathan Swift and the Dublin musical scene', Dublin Historical Record xlvii, 2 (Autumn 1994),

136-7.

[57] Laurence Whyte, Poems on various subjects (Dublin, 1740), p. 156 ('The Black Joke' is still current Ireland as 'The Humours of Bandon'.). The popularity of Italian music in Dublin during the 1730s surprised a French visitor to the city (Four letters originally written in French relating to the kingdom of Ireland (Dublin, 1739), pp 22-3).

[58] A country dance was a particular tune and the set of figures danced to it; the name of the dance was the name of the tune. The term eventually came to mean a longways progressive dance in both Ireland and England (J.F and T.M. Flett, 'The Scottish country dance: its origins and development I', Scottish Studies xi (1967), 2). A number of country dances survive as what are now called 'céilí-dances' - 'St Patrick's Day', for example, 'The High Caul Cap' and 'The Humours of Bandon' (Nicholas Carolan, 'The beginnings of céilí dancing: London in the 1890s', Céim lxix (Meán Fómhair 1990), 8-9).

[59] For a list of Neal publications, see Carolan, Celebrated Irish tunes, pp xxi-xxiii.

[60] NLI JM 5764. My thanks to Nicholas Carolan for confirming the identity of this collection.

[61] 'My Ain Kind Dearie' in the collection replaces 'Captain Tom' of the newspaper-advertisement.

[62] William C. Smith and Charles Humphries, A bibliography of the musical works published by the firm of John Walsh during the years 1721-1786 (London, 1968), pp 64-5.

[63] Panorama; ... , p. viii.

[64] Donnelly, 'Larry Grogan', 45-9.

[65] Ibid., 46, 50.

[66] John C. Greene and Gladys H. Clark (eds.), The Dublin stage, 1720-1745: a calendar of plays, entertainments and after-pieces (Cranbury [NJ] and London, 1993), passim., pp 190, 191.

[67] Faulkner's Dublin Journal, 30 April 1743. For this performer and his instrument, see Brian Boydell, 'Mr. Pockrich and the musical glasses', Dublin Historical Record xlvii, 2 (Autumn 1991), 25-33.

[68] See Seán Donnelly, 'A German dulcimer player in eighteenth-century Dublin', Dublin Historical Record liii, 1 (Spring 2000), forthcoming.

[69] Nicholas Carolan, 'Pipes and piping in 18th century Kildare', An Píobaire II, 20 (Marta 1984), 4.

[70] Panorama; ..., pp viii-ix.

[71] O'Farrell's collection of national Irish music for the union pipes, ... (London, 1804), pp 18-19.

[72] John Walsh, Caledonian country-dances book the second (London, 1737), p. 23. 'Larry Grogan' also appeared in this collection (p. 63), but in a rearranged form better-suited to the Irish pipes.

[73] Waylet's twenty four country-dances for the year 1749 (London, 1749), according to Francis O'Neill, Waifs and strays of Gaelic melody (2nd, enl. ed., Chicago, 1922), p. 127, who wrongly dates this collection to 1751.

[74] James Oswald, Oswald's Caledonian pocket-companion (12 parts, London, c. 1755-60), x, p 12.

[75] In possession of the Earl of Ancaster, Drummond Castle, Crieff, Scotland. Zerox copy in National Library of Scotland, NLS MS Acc. 7722, p. 4. I wish to thank Matt Seattle for this reference and for those in Notes 76, 78, 80 and 121 below.

[76] Matt Seattle (ed.), The Master Piper: nine notes that shook the world (Newbiggin-by-the-Sea, 1995), pp 5, 6, 78-9.

[77] George S. Emmerson, Rantin' Pipe and Tremblin' String: a history of Scottish dance-music (London, 1971), p. 229.

[78] NLS MS 3378, p. 4; Matthew Seattle (ed.), The Great Northern Tune Book: William Vickers' collection of country dance music (3 vols, Blyth, 1986), I [p. 2]

[79] John Peacock, A favourite collection of tunes with variations adapted for the Northumberland small pipes (Newcastle-upon-Tyne, c. 1804), p. 23.

[80] William Gunn, The Caledonian repository of music adapted for the Highland bagpipe (Glasgow, 1848), p. 64.

[81] Gordon J. Mooney, A collection of the choicest Scots tunes for the Lowland or Border bagpipes (2 vols., Edinburgh 1982-3). Rev. ed. in one vol. (Edinburgh, 1990), p. 90; idem., 'O'er the Border', Northumbrian Pipers' Magazine v (1984), 4.

[82] Kerr's fourth collection of merry melodies (Glasgow, n.d. [1920]), no. 75. I owe this reference to Joe Crane.

[83] David Johnson, Scottish fiddle music in the eighteenth century (Edinburgh, 1984), p. 10.

[84] The Index of Irish Traditional Dance Music compiled by Breandán Breathnach and now in the Irish Traditional Music Archive, Merrion Square, Dublin 2, records only O'Farrell's example, a later reprint of it, and one other example, itself deriving from a printed source, in a late nineteenth-century manuscript collection.

[85] Trinity College Dublin, MS 3195, p. 152.

[86] The music of Ireland (Chicago, 1903), p. 238.

[87] The dance music of Ireland, (Chicago, 1907), p. 100.

[88] O'Neill, Waifs and strays ..., p. 127.

[89] See for example, NLI MS G569, pp 15-16 (c. 1805); NLI MS 7348, p. 191 (c. 1850); and the previously mentioned NLI MS 2983 (ii), pp 251-2, where P.W. Joyce has copied a twenty-strain version of 'Jack Lattin' from a source he designates 'Limerick MS'.

[90] O'Neill lived in Chicago for most of his long life, becoming chief of police there at the beginning of this century. Among the musicians from whom he collected, there were very few from Ulster. However, Sergeant James O'Neill (no relation), from co. Down, who played the fiddle, functioned as O'Neill's musical amanuensis, and also contributed hugely to his work (Nicholas Carolan, A Harvest Saved: Francis O'Neill and Irish music in Chicago (Cork, 1997), pp 33-4.

[91] Henry Morris, 'A Farney piper's tunes', Louth Archaeological Journal v, 2 (1922), 114.

[92] Francis O'Neill, Irish minstrels and musicians (Chicago, 1913), p. 383.

[93] Breandán Breathnach, 'Piper Jackson', Éigse Cheol Tíre ii (1974-5), 41-57.

[94] Johnny and Mickey Byrne, Ceol na dTéad, Clo Iar-Chonnachta (CIC 078), side 1, track 3. I am grateful to Caoimhín Mac Aoidh for drawing this recording to my attention.

[95] Breandán Breathnach (ed.), Ceol Rince na hÉireann II (Baile Átha Cliath, 1976), p. 95.

[96] Information from Caoimhín Mac Aoidh.

[97] The early history of the jig in Ireland is discussed in Seán Donnelly, 'The first Irish jig in print: a fresh look at Thomas D'Urfey's "A Scotch song made to the Irish Jigg, ..." (1684)', in the forthcoming Breandán Breathnach memorial volume.

[98] Breandán Breathnach, Folkmusic and dances of Ireland (Dublin, 1971; rev. ed., Dublin, 1977), p. 60; idem., Dancing in Ireland (Miltown Malbay, 1983), p. 24; Flett, Social dancing in England, p. 5.

[99] J.F and T.M. Flett, 'The history of the Scottish reel as a dance-form I', Scottish Studies xvi (1972), 93, 95

[100] The standard traditional repertoire would include reels like 'Miss MacLeod' ('Mrs Macleod of Raasay'), 'Lucy Campbell' ('Miss Louisa Campbell'), 'Lord Gordon' ('The Duke of Gordon's Rant'), 'The Lads of Laois' ('The Lads of Leith') and 'The Boyne Hunt' ('The Perthshire Hunt'), and many other Scottish immigrants.

[101] Joan Rimmer, 'Patronage, style and structure in the music attributed to Turlough Carolan', Early Music xv, 2 (May 1987), 169-70.

[102] Poems, pp 157-8.

[103] The famous 'Rakes of Mallow' was published in 1733 (Walsh, Caledonian country dances, p. 34)

[104] NLI JM 5764, p. 1. An 'Irish Reel' was published in the early 1750s, as well a reel called 'Shebeen House' (NLI JM 5738: Three groups of twenty four dances bound in one volume (the first being John Walsh, Twenty four country dances for the year 1751), II, nos. 19, 20).

[105] Greene and Clark (eds), The Dublin stage, 1720-1745: ..., passim.

[106] Brian Boydell, 'Georgian lollipops or the lighter side of classical music', in Hugh Shields (ed.), Popular music in eighteenth-century Dublin (Dublin, 1985), p. 9; Nicholas Carolan, ' "The Most Celebrated Irish Tunes:" The publishing of Irish music in the eighteenth century'. Ó Riada Memorial Lecture V (Cork, 1990), illustration 4 (1769).

[107] Carolan, Celebrated Irish tunes, pp xxiv-xxv, 5.

[108] Stanley Sadie (ed.), The new Grove dictionary of music and musicians, II, p. 189.

[109] Poems, p. xx.

[110] Ibid., p. 158.

[111] Johnson, Scottish fiddle music, p. 166.

[112] New Grove dictionary V, p. 666.

[113] Ibid.; Whyte, Poems, p. 158; Neal advertised Dubourg's 'Airs for the King's Birthday' in Faulkner's Dublin Journal, 17 Nov. 1730.

[114] Sir John Carr, The stranger in Ireland; or, a tour in the southern and western parts of the country in the year 1805 (London, 1806), p. 254.

[115] Donnelly, 'Larry Grogan', 49-53.

[116] Note 72 above.

[117] Bruce Olson, 'Some old songs, a personal choice', http @ www.erols.com/olsonw\index.html, under 'Geld him lasses, geld him'. As of September 1998, Olson had not yet located a copy of this song.

[118] Catalogue of English and American chapbooks and broadside ballads in Harvard College Library. Compiled by Charles Welsh and Charles H. Tillinghast (Cambridge, Mass., 1905). Rep. with intro. by Leslie Shepherd, (Detroit, 1968), p. 81. I wish to thank Nicholas Carolan for this reference.

[119] James Johnson (ed.), The Scots musical museum (5 vols., Edinburgh, 1787-1803), V, no. 430; James Kinsley (ed.), The poems and songs of Robert Burns (3 vols., Oxford, 1968), II, p. 847.

[120] Robert Burns, The Merry Muses of Caledonia. Ed. by James Burke and Sydney Goodsir Smith; intro. and notes by J. Delancey Ferguson. (London, 1965), p. 105.

[121] Thomas Crawford (ed.), Love, Labour and Liberty: the eighteenth-century Scottish lyric (Cheadle Hume, 1976), p. 17. The second line of the verse is duplicated, an obvious error, and has been deleted here.

[122] J. Collingwood Bruce and John Stokoe (eds), The Northumbrian minstrelsy (Newcastle-upon-Tyne, 1882). 2nd ed, with intro. by A.L. Lloyd (Hatboro [Penn.], 1965), p. 176. I wish to thank Joe Crane for this reference.

[123] Other Irish titles for this tune are 'All the Way to Galway', and 'The Road to Lisdoonvarna' (Breandán Breathnach, (eag.), Ceol rince na hÉireann II (Baile Atha Cliath, 1976), lgh. 63, 144, 172, 182.

[124] Jimmy O'Brien-Moran, Tramore, co. Waterford, the well-known uilleann piper, gave me this rhyme. It inspired him to learn O'Farrell's 1804 version of 'Jack Lattin' (Note 71 above), which he played on RTÉ I's traditional-music programme 'The High Reel' in late 1997.

[125] English as we speak it in Ireland, p. 172.

[126] Claude M. Simpson (ed.), The British broadside ballad and its music (New Brunswick [NJ], 1966), p. 717. See also, Seán Donnelly, ' "Trenchmore:" an Irish dance in Tudor and Stuart England?' @ http://www.setdance.com/journal/trenchmore.html.

[127] Kemps Nine Daies Wonder, p. 6.

[128] W.G. Smith (ed.), The Oxford dictionary of English proverbs (Oxford, 1935; 2nd, rev. ed., 1948), p. 128

[129] Only in the last fifteen-to-twenty years has a faster dance than the reel, the Kerry slide, become generally-known in social dancing.

[130] Leaves from the journal of our life in the Highlands from 1848 to 1861. ... Ed. by Arthur Helps (London, 1868), p. 184

[131] James Joyce, Ulysses: the corrected text, ed. by Hans Walter Gabler, et al. (London, 1986), pp 381-2.

[132] Ibid.

[133] Richard Ellman, James Joyce (Oxford and New York, 1959), pp. 26, 27, 34.

[134] Ibid., p. 27.

[135] James Joyce, A portrait of the artist as a young man (London, 1924). Penguin pbk ed. (London, 1960), pp 19-20.

[136] Weldon Thornton, Allusions in ULYSSES (Chapel Hill, 1961; 2nd ed. Chapel Hill, 1968), p. 370; Don Gifford and Robert J. Seidman, Notes for Joyce - an annotation of James Joyce's ULYSSES (New York, 1974), p. 383.


`;

const Ecstasy = () => {
	const renderers = {
		image: ({ alt, src, title }) => {
			const img = {
				'lattin1.gif': lattin1,
				'lattin2.jpg': lattin2,
				'lattin3.jpg': lattin3,
				'lattin4.jpg': lattin4,
				'lattin5.jpg': lattin5,
			}[src];

			return (
				<img src={img} alt={alt} title={title} />
			);
		},
		blockquote: ({ children, node }) => {
			const lines = children[0].props.children[0].props.value.split('\n')
			return (
				<blockquote>
					{lines.map(a => <>{a}<br /></>)}
				</blockquote>
			);
		}
	}

	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - Ecstasy in Eighteenth-Century Kildare?
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						Ecstasy in Eighteenth-Century Kildare?
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown renderers={renderers}>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default Ecstasy;
